import * as React from "react"

// styles
const headerStyle = {
  boxSizing: 'border-box',
  backgroundColor: "black",
  color: "#232129",
  padding: 20,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
  listStyleType: "none",
}
const listItemStyles = {
  marginBottom: 12,
  fontWeight: "300",
  letterSpacing: 1,
}
const linkStyles = {
  color: "#8954A8",
}
const containerStyles = {
  margin: "auto",
  width: "90%",
  maxWidth: "1000px",
}

// data
const links = [
  {
    text: "Documentation",
    url: "https://www.gatsbyjs.com/docs/",
  },
  {
    text: "Tutorials",
    url: "https://www.gatsbyjs.com/tutorial/",
  },
  {
    text: "Guides",
    url: "https://www.gatsbyjs.com/tutorial/",
  },
  {
    text: "API Reference",
    url: "https://www.gatsbyjs.com/docs/api-reference/",
  },
  {
    text: "Plugin Library",
    url: "https://www.gatsbyjs.com/plugins",
  },
  {
    text: "Cheat Sheet",
    url: "https://www.gatsbyjs.com/docs/cheat-sheet/",
  },
]

// markup
const TermsPage = () => {
  return (
    <div>
      <header style={headerStyle}>
        <title>Ta' Spiru Barber Shop</title>
        <img
          alt="Ta Spiru Logo"
          style={{width:'25%',minWidth:"100px",maxWidth:"100px",margin:'auto',display:"block"}}
          src={require("../images/taspirulogo.png")}
        />

      </header>

      <main style={containerStyles}>
          <h1>Terms & Conditions</h1>

            <p>Ta’ Spiru is a service that allows its users to queue for and book barbering and hairdressing services provided by Ta’ Spiru. Ta’ Spiru provides such services primarily through use of its own proprietary mobile applications (mobile apps) and also through its websites, tools and services, which are collectively referred to in this User Agreement as the “Ta’ Spiru Service” and which includes both singular and plural references as the specific context of a sentence in this User Agreement requires.</p>
            <p>This user agreement (the “User Agreement”) forms a binding agreement between you (“you” refers to the person accessing or using the Ta’ Spiru Service), and Norbert Falzon trading as ‘Ta’ Spiru’ with identity card number 183481(M) and Ta' Spiru Head Office, 35b, Labour Avenue Naxxar, NXR 9021 as his place of business (“Ta’ Spiru” or “we,” “us” or “our”), whether or not you are a registered user of Ta’ Spiru. Before you may use the Ta’ Spiru Service, you must read and accept all of the terms in, and linked to, this User Agreement and the Ta’ Spiru Privacy Policy (published at <a href="https://taspiru.com/privacy">https://taspiru.com/privacy</a>. By accepting this User Agreement, you agree that this User Agreement and the Privacy Policy will apply whenever you use a Ta’ Spiru Service. Some Ta’ Spiru Services, including without limitation, applications, websites, tools and services may have additional or other terms that we will provide to you when you use such Ta’ Spiru Services. If you use any part of the Ta’ Spiru Service that has a separate user agreement and/or privacy policy, you agree to accept the terms and conditions of that user agreement and privacy policy prior to your first use thereof. We reserve the right, at our sole discretion, to change, modify, add, or delete portions of this User Agreement at any time without further notice. If we do this, you must read and accept the new terms for this User Agreement to continue to access and use the Ta’ Spiru Service. Changes to the User Agreement will be posted to this page and the last revision dated will be indicated at the top of this page. </p>
            <h2>Using Ta’ Spiru</h2>
            <p>While using the Ta’ Spiru Service, you agree you will not:</p>
            <p>i.    Violate any laws, third party rights or our policies;</p>
            <p>ii.   Use the Ta’ Spiru Services if you are not able to form legally binding contracts, are under the age of 18 or the legal age of majority in your own country / jurisidiction, or are temporarily or indefinitely suspended from using the Ta’ Spiru Service;</p>
            <p>iii.  Interfere with other users’ use and enjoyment of the Ta’ Spiru Service;</p>
            <p>iv.   Transfer your Ta’ Spiru account, share your Ta’ Spiru credentials and/or user identification information with another party without Ta’ Spiru’s consent;</p>
            <p>v.    Copy, modify or distribute rights or content from the Ta’ Spiru Service;</p>
            <p>vi.   Copy, reproduce, modify, create derivative works from, distribute, or publicly display any content (except for your own information) from the Ta’ Spiru Service without the prior express written permission of Ta’ Spiru and the appropriate third party, if applicable; and</p>
            <p>vii.  Interfere or attempt to interfere with the proper working of the Ta’ Spiru Service or any activities conducted on or with the Ta’ Spiru Service, including completing Check Ins at locations other than as specified in the Earn Activity.</p>
            <p>Abusing Ta’ Spiru</p>
            <p>We endeavor to ensure that the Ta’ Spiru Service works in a proper manner. </p>
            <p>Without prejudice to further action, we reserve the right to limit, suspend or terminate our service and specific user accounts, prohibit access to the Ta’ Spiru Service, and take technical and legal steps to keep users off the Ta’ Spiru Service if we believe that they are creating problems or acting inconsistently with the letter or spirit of Ta’ Spiru’s policies. Furthermore, we may, in appropriate circumstances and at our discretion, suspend or terminate accounts of users who may be repeat infringers of this User Agreement. We also reserve the right to cancel unconfirmed accounts, or accounts that have been inactive for a long time, or to modify or discontinue any part of the Ta’ Spiru Service.</p>
            <h2>Eligibility</h2>
            <p>By using the Ta’ Spiru Service, you represent and warrant that you are 18 years of age (or having attained the age of majority in your own country / jurisdiction) or older, and that you agree to and will abide by all of the terms and conditions of this User Agreement. Your Ta’ Spiru account may be terminated without warning if we believe that you are under 18 years of age, or if we for any reason believe you are misrepresenting your age. </p>
            <h2>Ta’ Spiru Data; Account Security</h2>
            <p>In consideration of your use of the Ta’ Spiru Service, you agree to (a) accurately provide all required information, as well as other information you elect to include, about you in any registration forms in connection with the Ta’ Spiru Service (including without limitation, the number for your valid personal mobile phone and your personal e-mail address) (“Ta’ Spiru User Information”); (b) maintain the security of your password and identification; (c) maintain and promptly update Ta’ Spiru User Information, and any other information you provide to Ta’ Spiru, to keep it accurate; and (d) be fully responsible for all use of your account and for any actions that take place using your account. </p>
            <p>In the event you change or deactivate your mobile telephone number or email address, you agree to update your account information on Ta’ Spiru within 24 hours to ensure that your messages are not sent to another person who acquires your old information. You may not use anyone else’s password or account.</p>
            <p>Communication from Ta’ Spiru</p>
            <p>When you visit or use the Ta’ Spiru Service or send e-mails to us, you are communicating with us electronically. You consent to receive communications from us electronically at any time. In order to stop receiving communications, you will need to unsubscribe from the Ta’ Spiru Service by sending an email to taspirubarbershop@gmail.com. Please be advised that message and data rates may apply to any communication you send to or receive from Ta’ Spiru. You are responsible for any such fees and/or charges, and under no circumstances shall Ta’ Spiru be responsible for any such charges you may incur. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. </p>
            <h2>Inactive Account </h2>
            <p>If you have not accessed your Ta’ Spiru account within the previous 9 months it will be deemed to be “Inactive” and  Ta’ Spiru may elect to close your account.  </p>
            <h2>Beta Versions</h2>
            <p>If you are using or participating in a beta version of the Ta’ Spiru Service, you understand and acknowledge that the beta version is in development and therefore is only an experimental model. During the beta test, the Ta’ Spiru Service might not function fully or adequately and might contain defects or other shortcomings. Furthermore, you agree to cooperate with Ta’ Spiru’s reasonable requests in connection with any beta program.</p>
            <h2>Queue and Booking activity</h2>
            <p>If you are using the Ta’ Spiru queue app you (‘the User’) agree that: </p>
            <p>i)    the estimated times indicated on the app are indeed purely estimates and therefore merely indicative.</p>
            <p>ii)   if the User does not turn up by the estimated time or by the time the previous customer is ready, that User’s spot in the queue will be forfeited and that user will be automatically charged a no-show fee;</p>
            <p>iii)  any booking can be cancelled for free for up to five (5) minutes after the booking is made. If it is cancelled after the lapse of afore-said time frame, a no-show fee will be cancelled;</p>
            <p>iv)   the User shall not take a seat with a different barber other than the one he was queued up with;</p>
            <p>v)    the User can opt to pay cash after the haircut or otherwise have the charge run through the card they initially inserted in the app;</p>
            <p>vi)   The User can add card details either before or after confirmation.</p>
            <h2>Disclaimers</h2>
            <p>The Ta’ Spiru Service may be temporarily unavailable from time to time for maintenance or other reasons. Ta’ Spiru assumes no responsibility for any error, omission, interruption, and/ordelay in operation or transmission.</p>
            <p>The Ta’ Spiru Service, any related applications and the Ta’ Spiru Service content are provided “as-is” and Ta’ Spiru disclaims any and all representations and warranties, whether express or implied. Ta’ Spiru cannot guarantee and does not promise any specific results from use of the Ta’ Spiru Service and/or any related applications. </p>
            <p>Ta’ Spiru reserves the right to change any and all content, software and other items used or contained in the Ta’ Spiru Service at any time without notice. </p>
            <h2>Limitation on Liability</h2>
            <p>In no event will Ta’ Spiru, employees or agents be liable to you or any third person for any indirect, consequential, incidental, special or punitive damages, including for any lost profits or lost data arising from your use of the Ta’ Spiru Service, any related applications or any of the Ta’ Spiru Service content or other materials on, accessed through or downloaded from the Ta’ Spiru Service, even if Ta’ Spiru is aware or has been advised of the possibility of such damages. </p>
            <h2>Governing Law; Venue and Jurisdiction</h2>
            <p>By visiting or using the Ta’ Spiru Service and/or the Service, you agree that the laws of Malta, without regard to principles of conflict of laws, will govern this User Agreement and any dispute of any sort that might arise between you and Ta’ Spiru or any of our affiliates. With respect to any disputes or claims not subject to arbitration (as set forth below), you agree not to commence or prosecute any action in connection with any such dispute other than in the Courts of Malta, and you hereby consent to such jurisdiction and venue, and waive all defenses of lack of personal jurisdiction or that Malta is an inconvenient place to hold any hearing regarding any dispute you may have with Ta’ Spiru.</p>
            <h2>Indemnity</h2>
            <p>You agree to indemnify and hold Ta’ Spiru, its subsidiaries and affiliates, and each of their directors, officers, agents, contractors, partners and employees, harmless from and against any loss, liability, claim, demand, damages, costs and expenses, including reasonable attorney’s fees, arising out of or in connection with your use of the Ta’ Spiru Service, your conduct in connection with the Ta’ Spiru Service or with other users of the Ta’ Spiru Service, or any violation of this User Agreement or of any law or the rights of any third party.</p>
            <h2>Submissions</h2>
            <p>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information about the Ta’ Spiru Service (“Submissions”), provided by you to Ta’ Spiru are non-confidential and shall become the sole property of Ta’ Spiru. Ta’ Spiru shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of these Submissions for any purpose, commercial or otherwise, without acknowledgment or compensation to you.</p>
            <h2>Definitions and Constructions</h2>
            <p>Unless otherwise specified, the terms “includes”, “including”, “e.g.,”, “for example”, and other similar terms are deemed to include the term “without limitation” immediately thereafter.</p>
            <h2>Miscellaneous</h2>
            <p>This User Agreement shall constitute the entire agreement between you and Ta’ Spiru regarding the use of the Ta’ Spiru Service, superseding any prior agreements between you and Ta’ Spiru relating to your use of the Ta’ Spiru Service. The failure of Ta’ Spiru to exercise or enforce any right or provision of this User Agreement shall not constitute a waiver of such right or provision in that or any other instance. If any provision of this User Agreement is held invalid, the remainder of this User Agreement shall continue in full force and effect. If any provision of this User Agreement shall be deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from this User Agreement and shall not affect the validity and enforceability of any remaining provisions.</p>
      </main>
      <footer style={{padding: 10}}>
        <p style={{fontSize:"12px"}}>Google Play and the Google Play logo are trademarks of Google LLC.</p>
      </footer>
    </div>
  )
}

export default TermsPage
